<template>
<CRow>
    <CCol sm="12">
        <CCard style="min-height: 120px;">        
            <CCardHeader class="pt-3">    
                <h2 class="pb-2">Dashboard</h2>
            </CCardHeader>
        </CCard>
    </CCol>
</CRow>
</template>
<script>
export default {
}
</script>